.adyen-checkout__dropdown__button-icon--left {
  flex-direction: row-reverse;
  justify-content: flex-end; }
  .adyen-checkout__dropdown__button-icon--left > img {
    margin-left: 0;
    margin-right: 12px; }

.adyen-checkout__dropdown {
  max-width: 100%;
  width: 100%;
  font-size: 1em; }

.adyen-checkout__dropdown__button {
  padding: 7px 24px 7px 12px;
  border: 1px solid #b9c4c9;
  background: #fff;
  color: #00112c;
  text-decoration: none;
  border-radius: 6px;
  outline: 0;
  width: 100%;
  font-size: 1em;
  height: 40px;
  line-height: 20px;
  user-select: none;
  transition: border 0.2s ease-out, box-shadow 0.2s ease-out; }
  [dir='rtl'] .adyen-checkout__dropdown__button {
    padding: 7px 12px 7px 24px; }

.adyen-checkout__dropdown__button:hover {
  border-color: #99a3ad; }

.adyen-checkout__dropdown__button__icon {
  margin-right: 8px;
  margin-left: auto;
  max-width: 40px;
  height: 26px;
  border-radius: 3px; }

.adyen-checkout__dropdown__button--active,
.adyen-checkout__dropdown__button--active:hover,
.adyen-checkout__dropdown__button:active,
.adyen-checkout__dropdown__button:focus {
  border-color: #06f;
  box-shadow: 0 0 0 2px #99c2ff; }

.adyen-checkout__dropdown__button--readonly, .adyen-checkout__dropdown__button--readonly--active, .adyen-checkout__dropdown__button--readonly:hover, .adyen-checkout__dropdown__button--readonly:focus {
  background: #e6e9eb;
  border-color: transparent;
  color: #00112c;
  cursor: not-allowed; }

.adyen-checkout__dropdown__button--readonly:after {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.19471 6.5646C3.59429 7.09797 4.39396 7.0986 4.79439 6.56587L7.78716 2.58424C8.28257 1.92514 7.81232 0.983398 6.98779 0.983398L1.01209 0.983398C0.188292 0.983398 -0.282154 1.92367 0.211778 2.58298L3.19471 6.5646Z' fill='%23B9C4C9'/%3E%3C/svg%3E%0A"); }

.adyen-checkout__dropdown__button--invalid {
  border-color: #d10244; }

.adyen-checkout__dropdown__button--valid {
  border-bottom-color: #0abf53; }

.adyen-checkout__dropdown__button__text {
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap; }

.adyen-checkout__dropdown__list {
  z-index: 2;
  border-radius: 6px;
  max-height: 375px;
  box-shadow: 0 2px 7px rgba(0, 15, 45, 0.3); }

.adyen-checkout__dropdown__list.adyen-checkout__dropdown__list--active {
  margin-top: 2px; }

.adyen-checkout__dropdown__element {
  padding: 8px;
  line-height: 20px;
  border: 1px solid transparent;
  word-break: break-word;
  hyphens: auto;
  cursor: pointer;
  font-size: 0.81em;
  outline: 0;
  user-select: none;
  transition: background 0.2s ease-out, border-color 0.2s ease-out; }

.adyen-checkout__dropdown__element:last-child {
  border-bottom: 0; }

.adyen-checkout__dropdown__element:hover,
.adyen-checkout__dropdown__element:focus,
.adyen-checkout__dropdown__element:active {
  background: rgba(230, 233, 235, 0.6); }

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active {
  background: rgba(0, 102, 255, 0.1); }

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:hover,
.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:focus,
.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:active {
  background: rgba(0, 102, 255, 0.15); }

.adyen-checkout__dropdown__element--disabled {
  opacity: 0.4;
  cursor: not-allowed; }

.adyen-checkout__dropdown__element__icon {
  border-radius: 3px;
  margin-right: 8px;
  max-width: 40px;
  max-height: 26px; }

.adyen-checkout__dropdown__element-icon--left {
  flex-direction: row-reverse;
  justify-content: flex-end; }

.adyen-checkout__dropdown__element__flag {
  margin-left: 8px;
  margin-right: 10px;
  max-width: 27px;
  max-height: 18px; }

.adyen-checkout__dropdown + .adyen-checkout-input__inline-validation {
  right: 32px; }
